@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: "band1s-bold";
    src: url("./assets/fonts/Bandeins-Strange-Bold/BandeinsStrange-Bold.otf")
      format("opentype");
  }

  @font-face {
    font-family: "band1s-boldx-half";
    src: url("./assets/fonts/Bandeins-Strange-Bold/BandeinsStrange-BoldExtendedHalf.otf")
      format("opentype");
  }

  @font-face {
    font-family: "band1s-boldx-full";
    src: url("./assets/fonts/Bandeins-Strange-Bold/BandeinsStrange-BoldExtendedFull.otf")
      format("opentype");
  }

  @font-face {
    font-family: "Sen";
    src: url("./assets/fonts/Sen/Sen-Regular.ttf") format("opentype");
  }

  @font-face {
    font-family: "Sen-Bold";
    src: url("./assets/fonts/Sen/Sen-Bold.ttf") format("opentype");
  }

  @font-face {
    font-family: "Sen-ExtraBold";
    src: url("./assets/fonts/Sen/Sen-ExtraBold.ttf") format("opentype");
  }
}

html,
body {
  margin: 0;
  padding: 0;
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  height: 100vh;
}

* {
  box-sizing: border-box;
}
